// Localstorage keys
export const Localstorage = {
  AuthStore: "auth.store",
  FingerprintStore: "fingerprint.store",
  LeafwatchStore: "leafwatch.store",
  ModeStore: "mode.store",
  NftOaCurrencyStore: "nftoacurrency.store",
  NotificationStore: "notification.store",
  TimelineStore: "timeline.store"
};

export const IndexDB = {
  AllowedTokensStore: "allowedtokens.store",
  ProfileStore: "profile.store",
  RateStore: "rate.store",
  SearchStore: "search.store",
  TransactionStore: "transaction.store",
  VerifiedMembersStore: "verifiedmembers.store"
};
