import {
  ApolloCache,
  ApolloProvider,
  gql,
  useApolloClient,
  useQuery
} from "@apollo/client";

import apolloClient from "./client";

export {
  ApolloCache,
  apolloClient,
  ApolloProvider,
  gql,
  useApolloClient,
  useQuery
};
